import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "52",
  height: "53",
  viewBox: "0 0 52 53",
  fill: "white",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "inherit",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M4.99623 49.5246L2 22.4301H20.8426L20.0322 21.6196C17.1115 18.699 15.4698 14.7374 15.4698 10.6058V3.51196H24.5303V7.08249C24.5303 11.7128 26.3704 16.1543 29.6443 19.4282L35.6708 25.4547V49.5246H4.99623ZM46.3671 49.5246H38.357V23.3274H50L46.3671 49.5246ZM46.5226 29.429C46.5226 31.0366 45.2194 32.3398 43.6118 32.3398C42.0043 32.3398 40.7011 31.0366 40.7011 29.429C40.7011 27.8215 42.0043 26.5183 43.6118 26.5183C45.2194 26.5183 46.5226 27.8215 46.5226 29.429Z"
    }, null, -1)
  ])))
}
export default { render: render }